<template id="ownerinfo">
  <v-ons-page id="ownerinfoPage">
    <v-ons-card>
      <div v-if="hasData">
        <div class="content">
          <div>
            <span>{{ FullName1 }}</span>
          </div>
          <div v-if="FullName2 != ''">
            <span>{{ FullName2 }}</span>
          </div>
          <div v-if="Company != ''">
            <span>{{ Company }}</span>
          </div>
          <div v-if="Address != ''" style="text-align: left;font-size:14px;">
            {{ Address }}
          </div>
          <v-ons-list>
            <v-ons-list-item>
              <div
                v-if="OfficeTel != ''"
                style="text-align: left; margin: 10px 0 0 10px; font-size:14px;font-style:normal "
              >
                <v-ons-icon icon="md-phone" style="color: blue;"></v-ons-icon>
                Office: {{ OfficeTel }}
              </div>
              <div
                v-if="MobileTel != ''"
                style="text-align: left; margin: 10px 0 0 10px; font-size:14px;font-style:normal "
              >
                <i class="zmdi zmdi-phone" style="color: blue;"></i>
                Mobile: {{ MobileTel }}
              </div>
              <div
                v-if="HomeTel != ''"
                style="text-align: left; margin: 10px 0 0 10px; font-size:14px;font-style:normal "
              >
                <i class="zmdi zmdi-phone" style="color: blue;"></i>
                Home: {{ HomeTel }}
              </div>
              <div
                v-if="Email1 != ''"
                style="text-align: left;margin: 10px 0 0  10px; font-size:14px;font-style:normal "
              >
                <i class="zmdi zmdi-email" style="color: blue;"></i>
                Email: {{ Email1 }}
              </div>
              <div
                v-if="Email2 != ''"
                style="text-align: left;margin: 10px 0 0  10px; font-size:14px;font-style:normal "
              >
                <i class="zmdi zmdi-email" style="color: blue;"></i>
                Email: {{ Email2 }}
              </div>
            </v-ons-list-item>
          </v-ons-list>
          <v-ons-list>
            <v-ons-list-item
              >Summary
              <v-ons-row style="height:100px;" vertical-align="center">
                <v-ons-col class="info-col" vertical-align="center" width="50%">
                  <div>
                    <img
                      src="../assets/img/property1.png"
                      alt="No Of Properties"
                      style="width:30px;height:30px;"
                    />
                  </div>
                  <div class="info-num">{{ NoOfProp }}</div>
                  <div class="info-unit">Properties</div>
                </v-ons-col>
                <v-ons-col class="info-col" vertical-align="center" width="50%">
                  <div>
                    <img
                      src="../assets/img/portfolio1.png"
                      alt="Total Portfolio"
                      style="width:30px;height:30px;"
                    />
                  </div>
                  <div class="info-num currency">{{ TotalPortfolio }}</div>
                  <div class="info-unit">Portfolio Value</div>
                </v-ons-col>
              </v-ons-row>
            </v-ons-list-item>
          </v-ons-list>
          <p style="font-size:xx-small">{{ owner.db }}</p>
        </div>
      </div>
      <div
        v-else
        style="text-align: center; font-size: 20px; margin-top: 20px; "
      >
        Owner information is coming...
      </div>
    </v-ons-card>
    <v-ons-fab
      v-show="hasData && !ReadOnlyDB"
      position="bottom right"
      :disabled="false"
      @click.prevent="
        router.push({ name: 'editowner', params: { owner: owner } })
      "
    >
      <v-ons-icon icon="ion-ios-create"></v-ons-icon>
    </v-ons-fab>
  </v-ons-page>
</template>
<script>
// Import the EventBus we just created.
import EventBus from "../config/event-bus";
import router from "../router";
export default {
  data: function() {
    return {
      FullName1: "",
      FullName2: "",
      Company: "",
      Address: "",
      HomeTel: "",
      OfficeTel: "",
      MobileTel: "",
      Email1: "",
      Email2: "",
      NoOfProp: 0,
      TotalPortfolio: 0,
      hasData: false,
      ReadOnlyDB: false,
      actionSheetVisible: false,
      router: router,
      owner: null,
      coordinate:null,
    };
  },
  activated() {
    EventBus.$on("UpdateMap", (data) => {
      console.log("updatemap");
      console.log("this.coordinate in goToMap:",this.coordinate)
      this.goToMap();
    });
  },
  mounted() {
      EventBus.$on("get_parcelcoordinate", (data) => {
      console.log("Get coordinate event in ownerinfo: ", data);
      this.coordinate = data;
      console.log("this.coordinate:",this.coordinate)
    }),
    EventBus.$on("get_db-contactid", (data) => {
      console.log("Get db contactid event in ownerinfo: ", data);
      //this.getPortfolioRows(data);
    }),
      EventBus.$on("get_owner", (data) => {
        console.log("Get owner event in ownerinfo: ");

        if (data !== null) {
          this.owner = data;
          this.FullName1 = (data.FirstName1 + " " + data.LastName1).trim();
          this.FullName2 = (data.FirstName2 + " " + data.LastName2).trim();
          this.Company = data.Company.trim();
          this.Address = data.Address.trim();
          this.HomeTel = data.HomeTel.trim();
          this.OfficeTel = data.ContactNo.trim();
          this.MobileTel = data.MobileTel.trim();
          this.Email1 = data.Email_key3.trim();
          this.Email2 = data.Email.trim();
          this.NoOfProp = data.NoOfProperties.trim();
          this.TotalPortfolio = data.TotalPortfolio.trim();
          this.hasData = true;
          if (this.owner.db == "bc") {
            this.ReadOnlyDB = true;
          } else {
            this.ReadOnlyDB = false;
          }
        } else this.hasData = false;
      });
  }, // end created
  methods: {
    goToMap() {
      console.log("this.coordinate:",this.coordinate)
      EventBus.$emit("go2map", this.coordinate);
    },
  },
  computed: {
    hasOwnerInfo() {
      //return (this.hasData)
      return true;
    },
  },
};
</script>
<style scoped>
.info-col {
  height: 100px;
  line-height: 1;
  padding: 12px 0 12px 4px;
}

.info-num {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
}

.info-unit {
  margin-top: 6px;
  font-size: 12px;
  opacity: 0.4;
}

.currency:before {
  content: "$";
}
/* .currency:after{ content: '.00'; } */

/* Style buttons */
.btn {
  background-color: transparent; /* Blue background */
  border: none; /* Remove borders */
  color: blue; /* White text */
  padding: 5px 5px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

/* Darker background on mouse-over  */
.btn:hover {
  color: red; /* White text */
}
</style>
