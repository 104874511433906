<template id="posts2">
  <v-ons-page id="posts2Page">
    <v-ons-carousel
      direction="horizontal"
      fullscreen
      auto-scroll
      overscrollable
      id="horizontal-carousel2"
    >
      <v-ons-carousel-item style="background-color: #373b44">
        <v-ons-page>
          <v-ons-toolbar>
            <div class="center" style="margin-top:7px;">
              <input
                type="search"
                id="txtPostSearch"
                autocomplete="off"
                v-model="strPostSearch"
                @keyup.enter="SearchPosts"
                placeholder="Search Posts"
              />
            </div>
          </v-ons-toolbar>

          <div v-show="propfilter.length > 0 || userfilter.length > 0">
            <ons-list v-show="propfilter.length == 0">
              <ons-list-item>
                <label class="center" for="switch1">
                  Your Posts Only
                </label>
                <div class="right">
                  <v-ons-switch
                    input-id="switch1"
                    v-model="switchOn"
                    @change="toggleUserFilter"
                  >
                  </v-ons-switch>
                </div>
              </ons-list-item>
            </ons-list>
            <v-ons-button
              v-show="propfilter.length > 0"
              modifier="outline"
              style="border-radius:12px;margin: 6px 3px"
              @click="propfilter = ''"
              >{{propfilteraddress}}
              <v-ons-icon icon="md-close-circle"></v-ons-icon
            ></v-ons-button>
            <!--<v-ons-button  v-show="propfilter.length==0" modifier="outline" style="border-radius:12px;margin: 6px 3px" @click="toggleUserFilter">{{userfilerButtonCaption}} <v-ons-icon icon="md-close-circle"></v-ons-icon></v-ons-button>-->
          </div>
          <div>
            <div v-if="filteredPosts.length">
              <!-- filteredPosts.length -->

              <div class="content" style="height: 85%">
                <div v-for="post in filteredPosts" class="post" :key="post.id">
                  <v-ons-card :id="post.id">
                    <v-ons-list-item class="postlistitem">
                      <div class="left">
                        <img
                          class="list-item__thumbnail"
                          :src="
                            post.userPhotoURL
                              ? post.userPhotoURL
                              : 'https://ui-avatars.com/api/?rounded=true&name=' +
                                post.userName
                          "
                        />
                      </div>
                      <div class="center">
                        <span
                          class="list-item__title"
                          style="color: blue"
                          @click="oneClick(post.id)"
                          >{{ post.userName }}</span
                        >
                        <span
                          class="list-item__subtitle"
                          style="font-weight: bold"
                          >{{ post.title }} &#183;
                          {{ post.createdOn | formatDate }}<br/>
                          </span>
                          <span class="list-item__subtitle" style="font-weight:bold;font-style:italic">
                          {{ post.selectedAddress }}</span
                        >
                      </div>
                      <div class="right">
                        <v-ons-icon
                          v-show="currentUser.uid == post.userId"
                          style="color: red;"
                          icon="md-delete"
                          class="list-item__icon"
                          @click="deletePost(post.id)"
                        ></v-ons-icon>
                      </div>
                    </v-ons-list-item>
                    <div
                      class="content"
                      v-bind:data-raw="post.content"
                      @click="expandPost"
                    >
                      {{ post.content | trimLength }}
                    </div>
                    <div style="margin-bottom:5px"></div>
                    <div>
                      <!--
                      <button
                        class="toolbar-button"
                        id="btnlike"
                        style="padding-left: 0px"
                      >
                        {{ post.likes }} likes
                      </button>
                      <button
                        class="toolbar-button"
                        id="btnComment"
                        @click="
                          viewPost(post);
                          change();
                        "
                      >
                        {{ post.comments }} Comments
                      </button>
                      -->

                      <button
                        class="toolbar-button"
                        id="btnLikes2"
                        @click="toggleLikeUnLike(post.id, post.likes)"
                      >
                        <i
                          :class="[
                            likedPosts.indexOf(post.id) != -1 ? 'fas' : 'far',
                            'fa-heart',
                          ]"
                          style="color:orange;font-size:20px; padding-left:0px;"
                        ></i>
                      </button>
                      <button
                        class="toolbar-button"
                        id="btnComment2"
                        @click="
                          viewPost(post);
                          change();
                        "
                      >
                        <i
                          class="far fa-comment-alt"
                          style="font-size:20px"
                        ></i>
                        {{ post.comments }}
                      </button>
                      <button
                        class="toolbar-button"
                        id="btnCopy"
                        @click="copy(post.content)"
                      >
                        <i
                          class="fa fa-copy"
                          style="font-size:20px; padding-left:0px;"
                        ></i>
                      </button>
                      <button
                        class="toolbar-button"
                        style="float: right"
                        id="btnLikes2"
                        @click="getLikedUserList(post.id)"
                      >
                        <i
                          class="fas fa-heart"
                          style="font-size:20px; padding-left:0px;"
                        ></i>
                        {{ post.likes }}
                      </button>
                      <button
                        v-show="post.centre"
                        style="float: right"
                        class="toolbar-button"
                        id="btnMap"
                        @click="go2Map(post)"
                      >
                        <i
                          class="fas fa-map-marked"
                          style="font-size:20px; padding-left:0px;"
                        ></i>
                      </button>
                    </div>
                  </v-ons-card>
                </div>
              </div>
            </div>
            <div v-else>
              <v-ons-card>
                <div class="content">
                  <p
                    style="text-align: center; font-size: 20px; margin-top: 20px; "
                    class="no-results"
                  >
                    There are no posts.
                  </p>
                </div>
              </v-ons-card>
            </div>
          </div>
        </v-ons-page>
      </v-ons-carousel-item>

      <v-ons-carousel-item style="background-color: #373b44">
        <v-ons-page>
          <v-ons-toolbar style="background: #E59866;">
            <div class="left">
              <v-ons-toolbar-button
                style="color: white"
                modifier="quiet"
                @click="back()"
              >
                <i class="fa fa-arrow-left"></i>
              </v-ons-toolbar-button>
            </div>
            <div class="center" style="color: white">
              {{ postComments.length }} Comments
            </div>
          </v-ons-toolbar>
          <div class="content" style="height: 85%;">
            <v-ons-card style="display: block;background-color:honeydew;">
              <v-ons-list-item class="postlistitem">
                <div class="left">
                  <img
                    class="list-item__thumbnail"
                    :src="
                      fullPost.userPhotoURL
                        ? fullPost.userPhotoURL
                        : 'https://ui-avatars.com/api/?rounded=true&name=' +
                          fullPost.userName
                    "
                  />
                </div>
                <div class="center">
                  <span
                    class="list-item__title"
                    style="color: blue"
                    @click="oneClick(fullPost.id)"
                    >{{ fullPost.userName }}</span
                  >
                  <span class="list-item__subtitle" style="font-weight: bold"
                    >{{ fullPost.title }} &#183;
                    {{ fullPost.createdOn | formatDate }}</span
                  >
                  <span class="list-item__subtitle" style="font-weight:bold;font-style:italic">
                          {{ fullPost.selectedAddress }}</span>
                </div>
                <div class="right">
                  <v-ons-icon
                    v-show="currentUser.uid == fullPost.userId"
                    style="color: red;"
                    icon="md-delete"
                    class="list-item__icon"
                    @click="deletePost(fullPost.id)"
                  ></v-ons-icon>
                </div>
              </v-ons-list-item>
              <div class="content">
                <VueShowdown
                  v-bind:markdown="fullPost.content ? fullPost.content : ''"
                  flavor="github"
                  :options="{ emoji: true }"
                />
                <div></div>
              </div>
              <div>
                <button
                  class="toolbar-button"
                  id="btnlike"
                  style="padding-left: 0px"
                >
                  <i
                    class="fas fa-heart"
                    style="font-size: 20px; padding-left: 0px"
                  ></i>
                  {{ fullPost.likes }}
                </button>
              </div>
            </v-ons-card>
            <div v-show="postComments.length">
              <div v-for="comment in postComments" :key="comment.id">
                <v-ons-card class="box">
                  <div class="content">
                    <v-ons-list-item class="postlistitem">
                      <div class="left">
                        <img
                          class="list-item__thumbnail"
                          :src="
                            comment.userPhotoURL
                              ? comment.userPhotoURL
                              : 'https://ui-avatars.com/api/?rounded=true&name=' +
                                comment.userName
                          "
                        />
                      </div>
                      <div class="center">
                        <span
                          class="list-item__title"
                          style="color: blue"
                          @click="oneClick(fullPost.id)"
                          >{{ comment.userName }}</span
                        >
                        <span
                          class="list-item__subtitle"
                          style="font-weight: bold"
                          >{{ comment.title }}</span
                        >
                      </div>
                    </v-ons-list-item>
                    <div class="content">
                      {{ comment.content
                      }}<span style="float: right; font-style: italic"></span
                      ><span class="timestamp">{{
                        comment.createdOn | formatDate
                      }}</span>
                    </div>
                    <button
                      class="toolbar-button"
                      id="btnCommentLikes2"
                      @click="
                        toggleCommentLikeUnLike(comment.id, comment.likes)
                      "
                    >
                      <i
                        :class="[
                          likedComments.indexOf(comment.id) != -1
                            ? 'fas'
                            : 'far',
                          'fa-heart',
                        ]"
                        style="color:orange;font-size:20px; padding-left:0px;"
                      ></i>
                    </button>
                    <button
                      class="toolbar-button"
                      style="float: right"
                      id="btnCommentLikes2"
                      @click="getCommentLikedUserList(comment.id)"
                    >
                      <i
                        class="fas fa-heart"
                        style="font-size:20px; padding-left:0px;"
                      ></i>
                      {{ comment.likes }}
                    </button>
                  </div>
                </v-ons-card>
              </div>
            </div>
          </div>

          <div id="commentbox" class="subdiv">
            <form @submit.prevent>
              <textarea
                class="textarea"
                rows="2"
                placeholder="Write a comment..."
                v-model.trim="comment.content"
              ></textarea>
              <button
                class="button--large--cta"
                style="width: 20%; margin: 0 auto;"
                @click="addComment()"
                :disabled="comment.content == ''"
              >
                Send
              </button>
            </form>
          </div>
        </v-ons-page>
      </v-ons-carousel-item>
    </v-ons-carousel>
    <v-ons-dialog cancelable :visible.sync="dialogVisible">
      <p>
        <v-ons-card>
          <span style="font-weight: bold;">Persons like the post</span>
          <v-ons-list v-for="(item, index) in likeduserlist" :key="index">
            <v-ons-list-item class="postlistitem">
              <div class="left">
                <img
                  class="list-item__thumbnail"
                  :src="
                    item.photoURL
                      ? item.photoURL
                      : 'https://ui-avatars.com/api/?rounded=true&name=' +
                        item.username
                  "
                />
              </div>
              <div class="center">
                <span class="list-item__title">{{ item.username }}</span>
              </div>
            </v-ons-list-item>
          </v-ons-list>
        </v-ons-card>
      </p>
    </v-ons-dialog>
    <v-ons-dialog cancelable :visible.sync="dialogCommentVisible">
      <p>
        <v-ons-card>
          <span style="font-weight: bold;">Persons like the comment</span>
          <v-ons-list
            v-for="(item, index) in commentlikeduserlist"
            :key="index"
          >
            <v-ons-list-item class="postlistitem">
              <div class="left">
                <img
                  class="list-item__thumbnail"
                  :src="
                    item.photoURL
                      ? item.photoURL
                      : 'https://ui-avatars.com/api/?rounded=true&name=' +
                        item.username
                  "
                />
              </div>
              <div class="center">
                <span class="list-item__title">{{ item.username }}</span>
              </div>
            </v-ons-list-item>
          </v-ons-list>
        </v-ons-card>
      </p>
    </v-ons-dialog>
    <v-ons-action-sheet :visible.sync="actionSheetVisible" cancelable>
      <v-ons-action-sheet-button icon="md-alert-circle"
        >Report spam
      </v-ons-action-sheet-button>
    </v-ons-action-sheet>
  </v-ons-page>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import fb from "../firebase";
import EventBus from "../config/event-bus";

export default {
  mounted() {
    EventBus.$on("propPosts", (data) => {
      console.log("filter prop posts", data);
      //this.propfilter = data.pid;
      this.propfilter=data.hash
      this.propfilteraddress = data.address? data.address:"Clear Filter";
      this.hash=data.hash;
    });
      EventBus.$on("viewpost", (data) => {
      console.log("post from taggedposts.vue:", data);
      this.fullPost=data
      this.viewPost(data)
      this.change()
       document.querySelector("ons-tabbar").setActiveTab(3);
    });
  },
  data() {
    return {
      postdialogVisible: false,
      commentsdialogVisible: false,
      post: {
        content: "",
      },
      comment: {
        postId: "",
        userId: "",
        content: "",
        postComments: 0,
      },
      showCommentModal: false,
      showPostModal: false,
      fullPost: {},
      postComments: [],
      strPostSearch: "",
      filterPosts: [],
      selectedPostObj: null,
      delay: 700,
      clicks: 0,
      timer: null,
      propfilter: "",
      propfilteraddress: "",
      userfilter: "",
      userfilerButtonCaption: "All Posts",
      switchOn: false,
      actionSheetVisible: false,
      likeduserlist: [],
      dialogVisible: false,
      commentlikeduserlist: [],
      dialogCommentVisible: false,
      hash:null,
    };
  },
  computed: {
    filteredPosts: function() {
      if (this.propfilter.length == 0) {
        return this.posts.filter((post) => {
          console.log("user filter:", this.userfilter);
          if (this.userfilter == "*") {
            return post.content.match(this.strPostSearch) && !post.deleted;
          } else {
            return (
              post.userId == this.currentUser.uid &&
              post.content.match(this.strPostSearch) &&
              !post.deleted
            );
          }
        });
      } else {
        return this.posts.filter((post) => {
          console.log("aPost:",post)
          if  (post.hash) {
            if (post.hash) {
           console.log("post.hash", post.hash);
           console.log("profilter:",this.propfilter)

              return post.hash == this.propfilter && !post.deleted;
            } else return false && !post.deleted;
          } else {
            return false && !post.deleted;
          }
        });
      }
    },

    ...mapState([
      "userProfile",
      "currentUser",
      "posts",
      "hiddenPosts",
      "likedPosts",
      "likedComments",
    ]),
  },
  created() {
    if (navigator.clipboard) {
      this.supportsCB = true;
    }
    if (this.currentUser) {
      this.userfilter = "*";
      //this.userfilter = this.currentUser.uid;
    }
  },
  methods: {
    expandPost(e) {
      e.target.innerHTML = e.target.dataset.raw;
    },
    getCommentLikedUserList(commentid) {
      fb.commentlikesCollection
        .where("commentId", "==", commentid)
        .get()
        .then((querySnapshot) => {
          let usercommentlikedlistData = [];
          let aUserName;
          querySnapshot.forEach(function(doc) {
            fb.usersCollection
              .doc(doc.data().userId)
              .get()
              .then((auser) => {
                aUserName = auser.data().username;
                usercommentlikedlistData.push(auser.data());
              });
            //userlikedlistData.push(doc.data().userId);
          });
          this.commentlikeduserlist = usercommentlikedlistData;
          console.log(" userlikedlistData", this.commentlikeduserlist);
          this.dialogCommentVisible = true;
        });
    },
    getLikedUserList(postid) {
      fb.likesCollection
        .where("postId", "==", postid)
        .get()
        .then((querySnapshot) => {
          let userlikedlistData = [];
          let aUserName;
          querySnapshot.forEach(function(doc) {
            fb.usersCollection
              .doc(doc.data().userId)
              .get()
              .then((auser) => {
                aUserName = auser.data().username;
                userlikedlistData.push(auser.data());
              });
            //userlikedlistData.push(doc.data().userId);
          });
          this.likeduserlist = userlikedlistData;
          console.log(" userlikedlistData", this.likeduserlist);
          this.dialogVisible = true;
        });
    },
    go2Map(apost) {
      console.log("apost", apost);
      EventBus.$emit("go2map", apost.centre);
      document.getElementById("maintabbar").setActiveTab(0);
    },
    toggleUserFilter() {
      if (this.userfilter == "*") {
        this.userfilter = this.currentUser.uid;
        this.userfilerButtonCaption = "Your posts only";
      } else {
        this.userfilter = "*";
        this.userfilerButtonCaption = "All posts";
      }
    },
    oneClick: function(postid) {
      this.clicks++;
      if (this.clicks === 1) {
        var self = this;
        this.timer = setTimeout(function() {
          //self.result.push(event.type);
          self.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        // this.result.push('dblclick');
        this.clicks = 0;
        this.deletePost(postid);
      }
    },
    setpost2delete(postid) {
      fb.postsCollection
        .doc(postid)
        .update({
          deleted: true,
        })
        .then((ref) => {
          console.log("Post deleted successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    SearchPosts() {
      console.log("SearchPosts", this.strPostSearch);
    },
    copy(postContent) {
      navigator.clipboard
        .writeText(postContent)
        .then(() => {
          this.$ons.notification.alert("Post content is copied.");
          console.log("Text is on the clipboard.");
        })
        .catch((e) => {
          console.error(e);
        });
    },
    deletePost(postid) {
      let that = this;
      console.log("deleting post:", postid);
      console.log("posts", that.posts);
      let aPost = that.posts.find((post) => post.id === postid);
      console.log("aPost", aPost);
      console.log("currentUserID", this.currentUser.uid);
      if (aPost.userId === this.currentUser.uid) {
        this.$ons.notification.confirm({
          title: "DELETE",
          messageHTML: "Do you really want to delete the post ?",
          buttonLabels: ["No", "Yes"],
          animation: "default",
          cancelable: true,
          callback: function(index) {
            if (index == -1) {
              console.log("confirm default");
              // that.selectedPostObj.style.backgroundColor="white"
            } else if (index == 0) {
              console.log("confirm:No");
              // that.selectedPostObj.style.backgroundColor="white"
            } else if (index == 1) {
              console.log("confirm:Yes");
              that.setpost2delete(postid);
            }
          },
        });
      } else {
        // that.selectedPostObj.style.backgroundColor="white"
        this.$ons.notification.alert("you can only delete your own post(s)!");
      }
    },
    createPost() {
      fb.postsCollection
        .add({
          createdOn: new Date(),
          content: this.post.content,
          userId: this.currentUser.uid,
          userName: this.currentUser.displayName,
          userPhotoURL: this.currentUser.photoURL,
          comments: 0,
          likes: 0,
        })
        .then((ref) => {
          this.post.content = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showNewPosts() {
      let updatedPostsArray = this.hiddenPosts.concat(this.posts);

      // clear hiddenPosts array and update posts array
      this.$store.commit("setHiddenPosts", null);
      this.$store.commit("setPosts", updatedPostsArray);
    },
    openCommentModal(post) {
      this.comment.postId = post.id;
      this.comment.userId = post.userId;
      this.comment.postComments = post.comments;
      this.showCommentModal = true;
      this.commentsdialogVisible = true;
    },
    closeCommentModal() {
      this.comment.postId = "";
      this.comment.userId = "";
      this.comment.content = "";
      this.showCommentModal = false;
      this.commentsdialogVisible = true;
    },
    addComment() {
      this.comment.postId = this.fullPost.id;
      this.comment.postComments = this.fullPost.comments;
      let postId = this.comment.postId;
      let postComments = this.comment.postComments;
      console.log("addcomment-postid:", postId);
      console.log("addcomment-content:", this.comment.content);
      fb.commentsCollection
        .add({
          createdOn: new Date(),
          content: this.comment.content,
          postId: postId,
          userId: this.currentUser.uid,
          userName: this.currentUser.displayName,
          userPhotoURL: this.currentUser.photoURL,
          likes: 0,
        })
        .then((doc) => {
          fb.postsCollection
            .doc(postId)
            .update({
              comments: postComments + 1,
            })
            .then(() => {
              this.closeCommentModal();
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleLikeUnLike(postId, postLikes) {
      if (this.likedPosts.indexOf(postId) != -1) {
        this.unlikePost(postId, postLikes);
      } else {
        this.likePost(postId, postLikes);
      }
    },
    unlikePost(postId, postLikes) {
      let docId = `${this.currentUser.uid}_${postId}`;

      fb.likesCollection
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            fb.likesCollection
              .doc(docId)
              .delete()
              .then(() => {
                fb.postsCollection.doc(postId).update({
                  likes: postLikes - 1,
                });
                console.log("unlike post successfully");
                return;
              })
              .catch((error) => {
                console.log("Error unlike post", error);
                return;
              });
            return;
          }
          console.log("currentUser", this.currentUser);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    likePost(postId, postLikes) {
      let docId = `${this.currentUser.uid}_${postId}`;

      fb.likesCollection
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return;
          }
          console.log("currentUser", this.currentUser);
          fb.likesCollection
            .doc(docId)
            .set({
              postId: postId,

              userId: this.currentUser.uid,
            })
            .then(() => {
              // update post likes
              fb.postsCollection.doc(postId).update({
                likes: postLikes + 1,
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleCommentLikeUnLike(commentId, commentLikes) {
      if (this.likedComments.indexOf(commentId) != -1) {
        this.unlikeComment(commentId, commentLikes);
      } else {
        this.likeComment(commentId, commentLikes);
      }
    },
    unlikeComment(commentId, commentLikes) {
      let docId = `${this.currentUser.uid}_${commentId}`;

      fb.commentlikesCollection
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            fb.commentlikesCollection
              .doc(docId)
              .delete()
              .then(() => {
                fb.commentsCollection.doc(commentId).update({
                  likes: commentLikes - 1,
                });
                console.log("unlike comment successfully");
                return;
              })
              .catch((error) => {
                console.log("Error unlike comment", error);
                return;
              });
            return;
          }
          console.log("currentUser", this.currentUser);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    likeComment(commentId, commentLikes) {
      let docId = `${this.currentUser.uid}_${commentId}`;

      fb.commentlikesCollection
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return;
          }
          console.log("currentUser", this.currentUser);
          fb.commentlikesCollection
            .doc(docId)
            .set({
              commentId: commentId,

              userId: this.currentUser.uid,
            })
            .then(() => {
              // update post likes
              fb.commentsCollection.doc(commentId).update({
                likes: commentLikes + 1,
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewPost(post) {
      fb.commentsCollection
        .where("postId", "==", post.id)
        //.get()
        .onSnapshot((docs) => {
          //.then((docs) => {
          let commentsArray = [];

          docs.forEach((doc) => {
            let comment = doc.data();
            comment.id = doc.id;
            commentsArray.push(comment);
          });

          this.postComments = commentsArray;
          this.fullPost = post;
          this.showPostModal = true;
          this.postdialogVisible = true;
        });
      /*
        .catch((err) => {
          console.log(err);
          this.fullPost = post;
          this.showPostModal = true;
          this.postdialogVisible = false;
        });
        */
    },
    closePostModal() {
      this.postComments = [];
      this.showPostModal = false;
    },
    change() {
      var carousel = document.getElementById("horizontal-carousel2");
      carousel.next();
    },
    back() {
      var carousel = document.getElementById("horizontal-carousel2");
      carousel.prev();
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
<style scoped>
.postlistitem {
  padding-left: 0px;
}

.commentcontent {
  font-size: 13px;
}
.timestamp {
  font-size: 10px;
  font-style: italic;
  color: green;
  float: right;
}
textarea {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  width: 80%;
  resize: none;
}

.subdiv {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}

* {
  box-sizing: border-box;
}

form {
  display: flex;
  padding: 1%;
  margin: 1%;
}

.box {
  border-radius: 25px;
}

#txtPostSearch {
  background-image: url("../assets/img/searchicon.jpg");
  background-size: 20px;
  background-position: 4px 10px;
  background-repeat: no-repeat;
  width: 97%;
  padding: 0px 5px 0px 30px;
  font-size: 16px;
  border: 1px solid #ddd;
  margin-bottom: 2px;
  margin-left: 5px;
  display: block;
  line-height: 40px;
}

#txtPostSearch::placeholder {
  font-size: 12px;
}
</style>
