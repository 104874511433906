<template id="propertyinfo">
  <v-ons-page id="propertyinfoPage">
    <v-ons-card>
      <div v-if="hasData">
        <div class="card__content">
          <v-ons-list-item>
            <v-ons-row>
              <div style="margin-bottom:10px;">
                <img
                  :src="AddressURL"
                  alt="Street View is not available."
                  style="display:block;width:100%;pointer-events:none"
                />
              </div>
            </v-ons-row>
            <div>
              <v-ons-row style="font-size: 18px;margin-bottom:10px">
                {{ Address }}
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Zoning:</v-ons-col>
                <v-ons-col>
                  {{ Zoning }}
                </v-ons-col>
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Registered Owner:</v-ons-col>
                <v-ons-col>
                  {{ LegalOwner }}
                </v-ons-col>
              </v-ons-row>

              <v-ons-row>
                <v-ons-col>Lot Size: </v-ons-col>
                <v-ons-col>{{ LotSize }}</v-ons-col>
              </v-ons-row>
              <div v-show="BuildingStoreys != ''">
                <v-ons-row>
                  <v-ons-col>Building Storeys: </v-ons-col>
                  <v-ons-col>{{ BuildingStoreys }}</v-ons-col>
                </v-ons-row>
              </div>
              <div v-show="NoOfUnits != ''">
                <v-ons-row>
                  <v-ons-col>No Of Units: </v-ons-col>
                  <v-ons-col>{{ NoOfUnits }}</v-ons-col>
                </v-ons-row>
              </div>
              <v-ons-row>
                <v-ons-col>Gross Tax: </v-ons-col>
                <v-ons-col>{{ GrossTax }}</v-ons-col>
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Assessment (year): </v-ons-col>
                <v-ons-col
                  >{{ AssessmentTotalValue }} ({{ AssessmentYear }})</v-ons-col
                >
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Land:</v-ons-col>
                <v-ons-col>{{ AssessmentLand }}</v-ons-col>
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Buildings:</v-ons-col>
                <v-ons-col>{{ AssessmentBuilding }}</v-ons-col>
              </v-ons-row>
            </div>
          </v-ons-list-item>
        </div>
      </div>

      <div
        v-else
        style="text-align: center; font-size: 20px; margin-top: 20px; "
      >
        Property information is coming...
      </div>
    </v-ons-card>
    <v-ons-card>
      <div v-if="hasData">
        <v-ons-list-item @click="showTitleSearch">
          <v-ons-icon
            icon="md-file-text"
            size="30px"
            style="margin-right: 10px;"
          ></v-ons-icon>
          Title search
        </v-ons-list-item>
        <v-ons-list-item @click="showCompanySearch">
          <v-ons-icon
            icon="md-file-text"
            size="30px"
            style="margin-right: 10px;"
          ></v-ons-icon>
          Company search
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-card >
      <div class="card__content">
        <v-ons-list-item tappable @click="go2propPosts">
          Tagged posts
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-dialog cancelable :visible.sync="showDialog">
      <PDFJSViewer :path="`${path}`" :name="`${name}`" />
    </v-ons-dialog>
  </v-ons-page>
</template>

<script>
// Import the EventBus we just created.
import EventBus from "../config/event-bus";
import PDFJSViewer from "../components/PDFJSViewer";
import fb from "../firebase";
export default {
  data: function() {
    return {
      PID: "",
      Address: "",
      Zoning: "",
      LotSize: 0,
      BuildingStoreys: 0,
      NoOfUnits: 0,
      GrossTax: 0,
      AssessmentTotalValue: 0,
      AssessmentYear: "",
      AssessmentLand: 0,
      AssessmentBuilding: 0,
      LegalOwner: "",
      hasData: false,
      hasPosts: true,
      AddressURL: "",
      dialogVisible: false,
      lonlat: null,
      showDialog: false,
      hasTitleSearch: false,
      hasCompanySearch: false,
      // for using pdf.js pre-build web viewer
      name: "Test_PDF.pdf", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.htmlm
      hash:null,

    };
  },
  created() {

    EventBus.$on("get_hash", (data) => {
      console.log("Get hash event in propertyinfo");
      this.hash = data;
      console.log("hash in propertyinfo:", this.hash);
    }),
    EventBus.$on("get_coordinate", (data) => {
      console.log("Get lonlat event in propertyinfo");
      this.lonlat = data;
    }),
      EventBus.$on("get_property", (data) => {
        console.log("Get property event in propertyinfo: ",data);
        if (data !== null) {
          this.PID = data.Pid;
          this.Address = data.CivicAddress;
          this.Zoning = data.Zoning;
          this.LotSize = data.LotSize;
          this.BuildingStoreys = data.BuildingStoreys;
          this.NoOfUnits = data.NoOfUnit;
          this.GrossTax = data.GrossTax.replace(/.(?=(?:.{3})+$)/g, "$&,");
          this.AssessmentTotalValue = data.AssessmentValue.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.AssessmentYear = data.AssessmentYear;
          this.AssessmentLand = data.CurrAssessLand.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.AssessmentBuilding = data.CurrAssessBuilding.replace(
            /.(?=(?:.{3})+$)/g,
            "$&,"
          );
          this.LegalOwner = data.LO;
          this.hasData = true;
          this.AddressURL =
            "https://maps.googleapis.com/maps/api/streetview?size=360x300&fov=120&pitch=10&source=outdoor&location=" +
            data.Address +
            "&key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs";
        } else 
        {
        
        
        this.hasData = false;


          this.PID = '';
          this.Address = '';
          this.Zoning = '';
          this.LotSize = '';
          this.BuildingStoreys = '';
          this.NoOfUnits ='';
          this.GrossTax = '';
          this.AssessmentTotalValue = '';
          this.AssessmentYear ='';
          this.AssessmentLand ='';
          this.AssessmentBuilding = '';
          this.LegalOwner = '';
          this.AddressURL ='';
      }
      });
  }, // end created
  computed: {
    hasPropInfo() {
      return this.hasData;
    },
  },
  components: {
    PDFJSViewer,
  },
  methods: {
    showCompanySearch() {
      const pdf_name_prefix = "corporatesearch/";
      var pdf_name = pdf_name_prefix + this.LegalOwner + ".pdf";
      var starsRef = fb.storage.ref().child(pdf_name);
      var that = this;
      // Get the download URL
      starsRef
        .getDownloadURL()
        .then(function(url) {
          console.log("pdf URL: " + url);
          //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
          that.path = "lib/web/viewer.html";
          that.showDialog = true;
          that.name = encodeURIComponent(url);
        })
        .catch((error) => {
          console.error("Company Search error:", error);
          this.$ons.notification.toast("No company search document found!", {
            timeout: 2000,
          });
        });
    },
    showTitleSearch() {
      const pdf_name_prefix = "titlesearch/";
      var pdf_name = pdf_name_prefix + this.PID + ".pdf";
      var starsRef = fb.storage.ref().child(pdf_name);
      var that = this;
      // Get the download URL
      starsRef
        .getDownloadURL()
        .then(function(url) {
          console.log("pdf URL: " + url);

          //EventBus.$emit("get_url", url);
          //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
          that.path = "lib/web/viewer.html";
          that.showDialog = true;
          that.name = encodeURIComponent(url);
        })
        .catch((error) => {
          console.error("Title Search error:", error);
          this.$ons.notification.toast("No title search document found!", {
            timeout: 2000,
          });
        });
    },
    go2propPosts() {
      let senddata = { pid: this.PID, address: this.Address ,hash:this.hash};
      EventBus.$emit("propPosts", senddata);
      document.querySelector("ons-tabbar").setActiveTab(4);
    },
  },
};
</script>
<style scoped>
.list-item__thumbnail {
  border-radius: 0%;
  width: 80%;
  height: 80%;
  cursor: pointer;
  margin: 0%;
}
img:hover {
  -webkit-transform: scale(5);
  transform: scale(5);
}
img {
  border: 1px solid #021a40;
}
</style>
