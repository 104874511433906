import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// manafund, acwchen@terradots.com account
const configOptions={
  apiKey: "AIzaSyBAimFsGueNruGRogC5DXhVU1LRjkYSYRw",
  authDomain: "manafund2022.firebaseapp.com",
  projectId: "manafund2022",
  storageBucket: "manafund2022.appspot.com",
  messagingSenderId: "49660887497",
  appId: "1:49660887497:web:c1d7960b6f282bdc90a818"
};

firebase.initializeApp(configOptions);


// firebase utils
const storage=firebase.storage()
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser
const fbemail_ID=firebase.auth.EmailAuthProvider.PROVIDER_ID
// firebase collections
const usersCollection = db.collection('users')

// team : L95NmDIgvQ6X337FiAKL

const postsCollection = db.collection('teams').doc('L95NmDIgvQ6X337FiAKL/').collection('posts')
const commentsCollection =db.collection('teams').doc('L95NmDIgvQ6X337FiAKL/').collection('comments')
const likesCollection = db.collection('teams').doc('L95NmDIgvQ6X337FiAKL/').collection('likes')
const commentlikesCollection = db.collection('teams').doc('L95NmDIgvQ6X337FiAKL/').collection('commentlikes')


//const postsCollection = db.collection('posts')
//const commentsCollection = db.collection('comments')
//const likesCollection = db.collection('likes')
//const commentlikesCollection = db.collection('commentlikes')

const corpsearchRef=db.collection('corpsearch')
const titlesearchRef=db.collection('titlesearch')


export default {
  //auth:firebase.auth(),
  storage,
  db,
  auth,
  currentUser,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
  fbemail_ID,
  corpsearchRef,
  titlesearchRef,
  commentlikesCollection
}
